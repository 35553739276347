<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <title-text
                        :title="PROVIDER.UsersPage.Title"
                        class="qa-users-title"
                    />
                </div>
            </div>
            <user-list></user-list>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

const TitleText = () => import('@/components/atoms/TitleText')
const UserList = () => import('@/components/organisms/Users/UserList')

export default {
    name: 'UsersOverview',
    components: {
        TitleText,
        UserList
    },
    data() {
        return {
            PROVIDER
        }
    }
}
</script>